<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <div style="margin-top: 20px" />
      <div class="detail-inner">
        <el-row :gutter="80">
          <el-col :span="6">
            <div class="show box flex">
              已授信总额度
            </div>
            <div class="show2 flex">
              {{ utils.moneyFormat(count.creditTotalAmount, 2) }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="show box flex">
              已授信客户总数
            </div>
            <div class="show2 flex">
              {{ utils.isEffectiveCommon(count.creditTotalCustomer) }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="show box flex">
              已放款总量
            </div>
            <div class="show2 flex">
              {{ utils.moneyFormat(count.loanTotalAmount, 2) }}
            </div>
          </el-col>
          <el-col :span="6">
            <div class="show box flex">
              本月放款总额
            </div>
            <div class="show2 flex">
              {{ utils.moneyFormat(count.currentMonthTotalAmount, 2) }}
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="detail-inner">
        <el-row :gutter="80" type="flex">
          <el-col :span="12">
            <div
              id="loanAmountAnalysis"
              style="width: 100%;height: 400px;"
            />
            <div class="show box1 flex" style="margin-top: -50px;">
              放款金额分析
            </div>
          </el-col>
          <el-col :span="12">
            <div
              id="loanSlipAnalysis"
              style="width: 100%;height: 400px;"
            />
            <div class="show box1 flex" style="margin-top: -50px;">
              放款单数分析
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="detail-inner">
        <el-row :gutter="80">
          <el-col :span="24">
            <div id="loanAssayChart" ref="loanAssayChart" style="width: 100%;height: 400px;" />
          </el-col>
        </el-row>
      </div>
    </div>
  </el-main>
</template>

<script>
import * as echarts from 'echarts/core'
import {
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  DataZoomComponent
} from 'echarts/components'
import {
  LineChart,
  BarChart,
  PieChart,
  SunburstChart
} from 'echarts/charts'
import {
  CanvasRenderer
} from 'echarts/renderers'
import { EleResize } from '@/assets/js/esresize'
import utils from '../../../assets/js/utils'
echarts.use(
  [ToolboxComponent, TooltipComponent, GridComponent, LegendComponent, MarkLineComponent, LineChart, BarChart, PieChart, SunburstChart, CanvasRenderer, DataZoomComponent]
)

export default {
  components: {},
  data() {
    return {
      count: {},
      analysis: {},
      slip: {},
      assayChart: [],
      loanAmountAnalysis: null,
      loanAssayChart: null,
      loanSlipAnalysis: null
    }
  },
  watch: {},
  mounted () {
    this.getCount()
    this.getLoanAmountAnalysis()
    this.getLoanSlipAnalysis()
    this.getLoanAssayChart()
  },
  methods: {
    drawLine (dom, option) {
      const myChart = echarts.init(document.getElementById(dom))
      const resizeDiv = document.getElementById(dom)
      myChart.setOption(option)
      const listener = function () {
        myChart.resize()
      }
      EleResize.on(resizeDiv, listener)
    },
    getCount () {
      this.api.dashboard.count().then(result => {
        this.count = result.data.data
      }).catch(e => {
      })
    },
    setLoanAmountAnalysis (option) {
      if (this.loanAmountAnalysis) this.loanAmountAnalysis.setOption(option)
    },
    setLoanSlipAnalysis (option) {
      if (this.loanSlipAnalysis) this.loanSlipAnalysis.setOption(option)
    },
    setLoanAssayChart (option) {
      if (this.loanAssayChart) this.loanAssayChart.setOption(option)
    },
    getLoanAmountAnalysis () {
      const dom = 'loanAmountAnalysis'
      this.loanAmountAnalysis = echarts.init(document.getElementById('loanAmountAnalysis'))
      this.api.dashboard.loanAmountChart().then(result => {
        this.analysis = result.data.data
        const data = [
          { name: '待放款额度(元)', value: this.analysis.waitLoanTotalAmount },
          { name: '已放款额度(元)', value: this.analysis.passLoanTotalAmount }
        ]
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: data
            }
          ]
        }
        this.drawLine(dom, option)
      })
    },
    getLoanSlipAnalysis () {
      const dom = 'loanSlipAnalysis'
      this.loanSlipAnalysis = echarts.init(document.getElementById('loanSlipAnalysis'))
      this.api.dashboard.loanAmountChart().then(result => {
        this.slip = result.data.data
        const data = [
          { name: '待放款数量(笔)', value: this.slip.waitLoanTotalNumb },
          { name: '已放款数量(笔)', value: this.slip.passLoanTotalNumb }
        ]
        console.log(data)
        const option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: '访问来源',
              type: 'pie',
              radius: ['50%', '70%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: '30',
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: data
            }
          ]
        }
        this.drawLine(dom, option)
      })
    },
    getLoanAssayChart () {
      const dom = 'loanAssayChart'
      this.loanAssayChart = echarts.init(document.getElementById('loanAssayChart'))
      this.api.dashboard.loanAssayChart().then(result => {
        const data = ['放款笔数(笔)', '放款额(元)']
        const monthsData = []
        const loanAmountData = []
        const passLoanTotalNumbData = []
        this.assayChart = result.data.data || []
        this.assayChart.forEach(item => {
          monthsData.push(item.months)
          loanAmountData.push(utils.moneyFormat(item.passLoanTotalAmount))
          passLoanTotalNumbData.push(item.passLoanTotalNumb)
        })
        console.log(loanAmountData)
        const option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              crossStyle: {
                color: '#999'
              }
            }
          },
          legend: {
            data: data
          },
          xAxis: [
            {
              type: 'category',
              data: monthsData,
              length: 7,
              axisPointer: {
                type: 'shadow'
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: '',
              interval: 200
            },
            {
              type: 'value',
              name: '',
              interval: 100
            }
          ],
          dataZoom: [
            {
              show: true,
              start: 0,
              end: 40
            },
            {
              type: 'inside',
              start: 94,
              end: 100
            }
          ],
          series: [
            {
              name: '放款笔数(笔)',
              type: 'bar',
              data: passLoanTotalNumbData
            },
            {
              name: '放款额(元)',
              type: 'line',
              yAxisIndex: 1,
              data: loanAmountData
            }
          ]
        }
        this.drawLine(dom, option)
      })
    }
  }
}
</script>

<style scoped>
.show{
  line-height:60px;
  font-size:18px;
  font-weight: bold;
}
.show2{
  line-height:80px;
  font-size:30px;
  font-weight: bold;
  /* font-family:'Times New Roman', Times, serif; */
  color:cornflowerblue;
}
.box{
  background-image: linear-gradient(180deg, #C9E3F6 0%, #C9E3F6 26%);
  box-shadow: 0 1px 2px 0 rgba(201,227,247,0.60);
  border-radius: 8px;
}
.main{

}
</style>
